<template>
  <div class="modal modal-center" id="taskDetails">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header d-block" v-if="ui.loadingTask === false">
        <div class="">
          Task / John Smith
        </div>
        <div class="form-group form-check form-check-round full-width task mt-2">
          <input type="checkbox" class="form-check-input" id="fieldCheckTask" v-model="fieldTaskToggle">
          <div class="">
            <label for="fieldCheckTask">Change loan amount to 250k & ask for documents</label>
            <div class="created-task">Created on May 13, 2020</div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5" v-if="ui.loadingTask === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="modal-body" v-if="ui.loadingTask === false">
        <div class="page-tabs d-flex justify-content-between mt-5 p-0" >
          <ul class="tab-list p-0">
            <li :class="{ active: tab === 'subTask' }" @click="setTab('subTask')">
              Sub-tasks
            </li>
            <li :class="{ active: tab === 'Comments' }" @click="setTab('Comments')">
              Comments
            </li>
            <li :class="{ active: tab === 'Attachments' }" @click="setTab('Attachments')">
              Attachments
            </li>
          </ul>
        </div>
        <div class="row mt-3">
          <div class="col-8">
            <div class="modal-body-container pb-3 border-0" v-if="tab === 'subTask'">
              <div class="form-group form-check subtask" v-for="subtask in task.subtasks" :key="subtask.id">
                <input type="checkbox" class="form-check-input"
                       :id="'fieldCheckSubTask_' + subtask.id">
                <label for="'fieldCheckSubTask_' + subtask.id">
                  {{ subtask.name }}
                </label>
              </div>
            </div>
            <div class="modal-body-container border-0 pb-3" v-if="tab === 'Comments'">
              <div class="comments">
                <div class="comment row mt-1" v-for="comment in task.comments" :key="comment.id">
                  <div class="commenter-avatar col-2 pe-0 text-center">
                    <img src="@/assets/icons/Nathan@2x.png" alt="">
                  </div>
                  <div class="comment-body col-10">
                    <div class="row">
                      <div class="col-10 ps-0">
                      <span class="commenter-name">
                        {{ comment.commenter }}
                      </span>
                      </div>
                      <div class="col-2 text-end comment-date ps-0">
                        {{ comment.date }}
                      </div>
                      <div class="col-12 comment-text ps-0">
                        {{ comment.comment }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body-container border-0 pb-3" v-if="tab === 'Attachments'">
              <div class="comments">
                <div class="comment row" v-for="attach in task.attachments" :key="attach.key">
                  <div class="commenter-avatar col-2 pe-0 text-center">
                    <img src="@/assets/icons/Nathan@2x.png" alt="">
                  </div>
                  <div class="comment-body col-10">
                    <div class="row">
                      <div class="col-10 ps-0">
                        <span class="commenter-name">
                          {{ attach.attacher }}
                        </span>
                      </div>
                      <div class="col-2 ps-0 text-end comment-date">
                        {{ attach.date }}
                      </div>
                      <div class="col-12 ps-0 comment-text">
                        {{ attach.attachText }}
                      </div>
                      <div class="col-12 ps-0">
                        <div class="attach-file">
                          <div class="">
                            <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                            <span class="file-name">{{ attach.attachFile }}</span>
                          </div>
                          <button>
                            <img src="@/assets/icons/icon-download.svg" alt="Delete">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div :class="{'d-block': tab === 'Attachments', 'd-block': tab === 'Comments', 'd-none': tab === 'subTask' }">
              <div class="form-group full-width ps-2 mt-4">
                  <textarea name=""
                            cols="30"
                            rows="3"
                            id="fieldComment"
                            class="form-control"
                            v-model="comment"
                            placeholder="Write a comment">
                  </textarea>
              </div>
              <div class="row mt-4">
                <div class="col-6 ps-4">
                  <button class="btn btn-control">
                    <img src="@/assets/icons/icon-attachment-18.svg" alt="">
                  </button>
                </div>
                <div class="col-6 text-end">
                  <button class="btn btn-primary">
                    Add Comment
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="modal-body-container pb-2">
              <label for="LoanName">
                Loan File
              </label>
              <div id="LoanName">
                John Smith
              </div>
            </div>
            <div class="modal-body-container pb-2">
              <label for="AssignedBy">
                Assigned by
              </label>
              <div id="AssignedBy">
                Nathan Kowarsky
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskDetailsModal",
  props: {
    taskId: Number
  },
  data() {
    return {
      ui: {
        loadingTask: false,
      },
      tab: 'subTask',
      fieldTaskToggle: false,
      comment: '',
      task: null
    }
  },
  methods: {
    taskLoad() {
      this.ui.loadingTask = true;
      setTimeout(() => {
        this.ui.loadingTask = false;
        this.task = {
          id: 0,
          subtasks: [{
            id: 1,
            name: 'test'
          }, {
            id: 2,
            name: 'test 2'
          }],
          comments: [{id: 1, commenter: 'Pavel Uvarov', date: '1', comment: 'test comment'}],
          attachments: [{id: 1, attachText: 'test', attachFile: 'test.pdf', attacher: 'Nathan Kowarcky', date: '1d'}]
        }
      }, 1500)
    },
    setTab(tabName) {
      this.tab = tabName;
    }
  },
  created() {
    this.taskLoad();
  }
}
</script>
<style lang="scss" scoped>
.subtask {
  border-bottom: 1px solid rgba(239,239,239,1);
  max-width: 100%;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.modal {
  .task {
    .created-task {
      opacity: 0.46;
      color: rgba(0,0,0,1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      margin-top: 10px;
    }
    &.form-check {
      label {
        color: rgba(0,0,0,1);
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 27px;
      }
    }
  }
  .form-check {
    label {
      color: rgba(0,0,0,1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      margin: 0;
    }
  }

  &-body {
    padding: 90px 30px;
  }
  .attach-file {
    height: 36px;
    max-width: 100%;
    width: 100%;
    margin: 10px 0 0 0 ;
  }
  .comments  {
    max-height: 400px;
    overflow: scroll;
  }
  .commenter-avatar {
    img {
      height: 37px;
      width: 37px;
      border: 1px solid rgba(237,237,238,1);
      border-radius: 50%;
   }
  }
  .comment-body {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(239,239,239,1);
  }
  .commenter-avatar {
    color: rgba(0,0,0,1);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  .comment-text {
    color: rgba(0,0,0,1);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 7px;
  }
  .comment-date {
    opacity: 0.46;
    color: rgba(0,0,0,1);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
  .modal-body-container {
    .form-check {
      label {
        color: rgba(0,0,0,1);
        font-size: 11px;
        letter-spacing: 0;
        line-height: 11px;
        margin: 0;
      }
    }
    .form-check-input {
      max-width: 18px;
      width: 100%;
      height: 18px;
      border-radius: 50% !important;
      margin-top: 0;
      margin-right: 10px;
      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }

    label {
      color: rgba(0,0,0,.7);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      font-weight: normal;
    }
  }
}

</style>
