<template>
  <div class="modal" id="selectFromModal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div class="modal-header">
        <div class="d-flex align-items-center">
          <button class="btn btn-control me-3">
            <img src="@/assets/icons/bold-icon previous.svg" alt="">
          </button>
          <h3 class="m-0">
            Select from LendingPad
          </h3>
        </div>
      </div>
      <div class="modal-body">
        <div class="search-wrapper">
          <img src="@/assets/icons/icon-search.svg" alt="Icon search">
          <input type="text" placeholder="Search">
        </div>
        <div class="modal-body-container mt-5">
          <div class="modal-body-container_title color-grey">
            Select Borrower
          </div>
          <div class="row borrowers-list">
            <div class="col-12 borrower d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <div class="borrower-avatar">
                  <img src="@/assets/icons/0 Default sm.png" alt="">
                </div>
                <div class="ms-3">
                  <div class="borrower-name">
                    Ronnie Phillips
                  </div>
                  <div class="borrower-amount">
                    $600,000
                  </div>
                </div>
              </div>
              <div class="Conventional d-flex align-items-end">
                Conventional 30 year fixed 2.75
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectFromModal",
}
</script>
